import { z } from 'zod';
import { Newtype } from 'newtype-ts';
import { emailSchema, nonEmptyStringSchema } from '@shared/schemas';

export type ResetPasswordToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;
export type ActivationToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;

export interface Profile {
  email: string;
  firstName: string | null;
  lastName: string | null;
  mustDeclareStock: boolean;
}

export const updateProfileSchema = z.object({
  lastName: z.string(),
  firstName: z.string(),
  entityName: nonEmptyStringSchema,
});

export type UpdateProfileParams = z.infer<typeof updateProfileSchema>;

export const authenticateSchema = z.object({
  email: emailSchema,
  password: nonEmptyStringSchema,
});

export type AuthenticateParams = z.infer<typeof authenticateSchema>;

export const forgotPasswordSchema = z.object({
  email: emailSchema,
});

export type ForgotPasswordParams = z.infer<typeof forgotPasswordSchema>;

export interface ResetPasswordParams {
  password: string;
}

export const passwordsSchema = z
  .object({
    password: nonEmptyStringSchema,
    confirmPassword: z.string().min(1, 'Champs requis'),
  })
  .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Les mots de passe ne correspondent pas',
  });

export type PasswordsFormBody = z.infer<typeof passwordsSchema>;

export interface ActivationDetail {
  email: string;
}

export interface ActivationParams {
  password: string;
}
