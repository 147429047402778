import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Box, Divider, Group, Paper, Text } from '@mantine/core';
import { Case, CaseStatus, caseStatusLabel } from '@modules/cases/model';
import { IconHome } from '@tabler/icons-react';

interface CaseRowProps {
  item: Case;
  index: number;
  forwardRef: (element: Element | null) => void;
  style: React.CSSProperties;
}

const CaseRow: FC<CaseRowProps> = ({ item, index, forwardRef, style }) => {
  return (
    <Box ref={forwardRef} data-index={index} style={style} my="md">
      <Paper component={Link} to={`/cases/${item.id}`} p="md">
        <Group>
          <Text fw={600}>{item.name}</Text>-<Text size={14}>{item.customerName}</Text>
          <Badge color={item.status === CaseStatus.InProgress ? 'blue' : 'red'} variant="filled" radius="sm">
            {caseStatusLabel[item.status]}
          </Badge>
        </Group>

        <Divider my="sm" size={2} />

        <Group>
          <IconHome stroke={1} />

          <Text size={14}>
            {item.address.firstLine} {item.address.postalCode} {item.address.city}
          </Text>
        </Group>
      </Paper>
    </Box>
  );
};

export default CaseRow;
