import { z } from 'zod';
import { iso, Newtype } from 'newtype-ts';

export const emailSchema = z.string().regex(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  { message: "Le format d'email est incorrect" },
);

export const nonEmptyStringSchema = z.string().min(1);

export const addressSchema = z.object({
  city: nonEmptyStringSchema,
  postalCode: nonEmptyStringSchema,
  firstLine: nonEmptyStringSchema,
});

export function createNewTypeStringSchema<T extends Newtype<unknown, string> = never>() {
  return nonEmptyStringSchema.transform(val => iso<T>().wrap(val));
}
