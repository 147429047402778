import React, { FC } from 'react';

import * as O from 'fp-ts/Option';

import { HttpError, HttpStatusCode } from '@core/http';
import { ForgotPasswordParams, forgotPasswordSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Anchor, Button, Group, Text, TextInput } from '@mantine/core';
import { Link } from 'react-router-dom';

interface ForgotPasswordFormProps {
  loading: boolean;
  error: O.Option<HttpError>;
  onSubmit: (params: ForgotPasswordParams) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordParams>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  return (
    <form style={{ width: '400px' }} onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {HttpStatusCode.NOT_FOUND === error.status
              ? "L'email n'est rattaché à aucun compte."
              : 'Une erreur technique est survenue.'}
          </Text>
        </Alert>
      ))}

      <Text size="sm" align="center" color="#ffffff" mb="lg">
        Merci de renseigner l'adresse email associée à votre compte. Nous vous enverrons les instructions afin de
        modifier votre mot de passe.
      </Text>

      <TextInput
        mt="md"
        type="email"
        id="email"
        placeholder="Entrez votre adresse email"
        required={!!errors.email}
        radius="md"
        mb="xl"
        {...register('email')}
      />

      <Group position="center" mt="lg">
        <Button type="submit" loading={loading} disabled={loading}>
          Valider
        </Button>
      </Group>

      <Group position="center" mt="sm">
        <Anchor component={Link} to="/login" underline size="sm" style={{ color: '#ffffff' }}>
          Retour
        </Anchor>
      </Group>
    </form>
  );
};

export default ForgotPasswordForm;
