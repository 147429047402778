import styled from '@emotion/styled';

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FiltersErase = styled.div`
  margin-left: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #cccccc;
  cursor: pointer;
`;
