import React, { FC } from 'react';

import { useForm } from 'react-hook-form';
import * as O from 'fp-ts/Option';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { HttpError, HttpStatusCode } from '@core/http';
import { AuthenticateParams, authenticateSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { Alert, Anchor, Button, Group, Text, TextInput } from '@mantine/core';
import styled from '@emotion/styled';

export const LoginFormInput = styled(TextInput)`
  > label {
    color: #ffffff;
  }
`;

interface LoginFormProps {
  loading: boolean;
  error: O.Option<HttpError>;
  onSubmit: (params: AuthenticateParams) => void;
}

const LoginForm: FC<LoginFormProps> = ({ loading, error, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AuthenticateParams>({
    resolver: zodResolver(authenticateSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <form style={{ width: '400px' }} onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {HttpStatusCode.UNAUTHORIZED === error.status
              ? 'Email ou mot de passe incorrect.'
              : 'Une erreur technique est survenue.'}
          </Text>
        </Alert>
      ))}

      <LoginFormInput
        id="email"
        type="email"
        label="Adresse email"
        placeholder="Adresse email"
        error={!!errors.email}
        radius="md"
        mb="lg"
        {...register('email')}
      />

      <LoginFormInput
        id="password"
        type="password"
        label="Mot de passe"
        placeholder="Mot de passe"
        error={!!errors.password}
        radius="md"
        {...register('password')}
      />

      <Group mt="md" position="right">
        <Anchor
          component={Link}
          underline
          to="/password-reset/forgot"
          size="xs"
          weight="600"
          style={{ color: '#ffffff' }}
        >
          Mot de passe oublié ?
        </Anchor>
      </Group>

      <Group mt="xl" position="center">
        <Button type="submit" loading={loading} disabled={loading}>
          Connexion
        </Button>
      </Group>
    </form>
  );
};

export default LoginForm;
