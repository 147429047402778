import React, { FC } from 'react';
import { useEnhancedFormContext } from '@shared/modules/form';
import { UpdateProfileParams } from '@modules/auth/model';
import { Paper, SimpleGrid, TextInput, Text } from '@mantine/core';

interface UpdateProfileFormProps {
  email: string;
}

const UpdateProfileForm: FC<UpdateProfileFormProps> = ({ email }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<UpdateProfileParams>();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Text fw={700}>Mes informations personnelles</Text>

      <Paper p="xl" mt="xs">
        <TextInput type="email" label="E-mail" required disabled value={email} />

        <SimpleGrid mt="md" cols={2}>
          <TextInput id="lastName" label="Nom" placeholder="Nom" error={!!errors.lastName} {...register('lastName')} />

          <TextInput
            id="firstName"
            label="Prénom"
            placeholder="Prénom"
            error={!!errors.firstName}
            {...register('firstName')}
          />
        </SimpleGrid>
      </Paper>

      <Text mt="xl" fw={700}>
        Ma société
      </Text>

      <Paper p="xl" mt="xs">
        <TextInput
          id="entityName"
          label="Nom de la société"
          placeholder="Nom de la société"
          error={!!errors.entityName}
          required
          {...register('entityName')}
        />
      </Paper>
    </form>
  );
};

export default UpdateProfileForm;
