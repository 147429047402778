import React, { FC } from 'react';
import { FilterChildrenProps } from '@shared/modules/filter';
import { CasesRangeFilter, CaseStatus, caseStatusLabel } from '@modules/cases/model';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import { Select } from '@mantine/core';

const CasesFilter: FC<FilterChildrenProps<CasesRangeFilter>> = ({ filter, onChange }) => {
  const handleChange = (value: CaseStatus | null) => {
    onChange({
      ...filter,
      status: value,
    });
  };

  return (
    <Select
      placeholder="Statut"
      data={getDropdownOptionsFromEnumLabels(caseStatusLabel)}
      value={filter.status}
      onChange={handleChange}
      mr="md"
    />
  );
};

export default CasesFilter;
