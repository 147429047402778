import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
`;

export const NavHeaderLogo = styled(Link)`
  display: block;
  width: 140px;
  background: #fff;
  padding: 10px 20px;
  border-radius: 20px;

  img {
    width: 100%;
  }
`;

export const NavUserContainer = styled.div`
  padding: 10px;
`;

export const NavUser = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NavUserLabel = styled.p`
  padding-right: 19px;
  font-size: 14px;
`;

export const NavUserDropdown = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
`;

export const NavUserDropdownLink = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-size: 14px;
`;
