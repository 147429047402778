import React, { FC } from 'react';
import { Button, Col, Grid, Paper, Select, Table, Text, TextInput } from '@mantine/core';
import { useEnhancedFormContext } from '@shared/modules/form';
import { caseTypeLabel, CreateCaseParams } from '@modules/cases/model';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import { Controller, useFieldArray } from 'react-hook-form';
import { IconArrowLeft, IconDeviceFloppy } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { DatePicker } from '@mantine/dates';

interface CaseFormProps {
  loading: boolean;
  onSubmit: () => void;
}

const CaseForm: FC<CaseFormProps> = ({ loading, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useEnhancedFormContext<CreateCaseParams>();

  const { fields } = useFieldArray({ control, name: 'products' });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Text fw={700}>Informations générales</Text>

      <Paper p="xl" mt="xs">
        <TextInput
          id="name"
          label="Référence du chantier"
          placeholder="Référence du chantier"
          required
          error={!!errors.name}
          {...register('name')}
        />

        <TextInput
          id="customerName"
          label="Client"
          placeholder="Client"
          required
          error={!!errors.customerName}
          {...register('customerName')}
          mt="md"
        />

        <Grid mt="md">
          <Col span={7}>
            <TextInput
              id="address.firstLine"
              label="Adresse du chantier"
              placeholder="Adresse du chantier 2"
              required
              error={!!errors.address?.firstLine}
              {...register('address.firstLine')}
            />
          </Col>

          <Col span={2}>
            <TextInput
              id="address.postalCode"
              label="CP"
              placeholder="00000"
              required
              error={!!errors.address?.postalCode}
              {...register('address.postalCode')}
            />
          </Col>

          <Col span={3}>
            <TextInput
              id="address.city"
              label="Ville"
              placeholder="Ville"
              required
              error={!!errors.address?.city}
              {...register('address.city')}
            />
          </Col>
        </Grid>
      </Paper>

      <Text fw={700} mt="xl">
        Installation
      </Text>

      <Paper p="xl" mt="xs">
        <Text>
          Pour pouvoir déclarer un nouveau chantier, ce dernier doit être installé (constat d'installation envoyé au
          client)
        </Text>

        <Controller
          control={control}
          name="installDate"
          render={({ field }) => (
            <DatePicker
              inputFormat="DD/MM/YYYY"
              placeholder="JJ/MM/AAAA"
              label="Date d'installation"
              error={!!errors.installDate}
              value={field.value}
              onChange={field.onChange}
              locale="fr"
              mt="md"
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              label="Quel est le type de votre chantier"
              data={getDropdownOptionsFromEnumLabels(caseTypeLabel)}
              value={field.value}
              error={!!errors.type}
              onChange={field.onChange}
              mt="md"
            />
          )}
        />
      </Paper>

      <Text fw={700} mt="xl">
        Superficies
      </Text>

      <Paper p="xl" mt="xs">
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Col span={3}>
            <Text>Superficie du bâti en m²</Text>
          </Col>

          <Col span={9}>
            <TextInput
              type="number"
              id="zoneInfo.buildingSurface"
              placeholder="Superficie du bâti"
              error={!!errors.zoneInfo?.buildingSurface}
              {...register('zoneInfo.buildingSurface')}
            />
          </Col>

          <Col span={3}>
            <Text>Superficie du terrain en m²</Text>
          </Col>

          <Col span={9}>
            <TextInput
              type="number"
              id="zoneInfo.landSurface"
              placeholder="Superficie du terrain"
              error={!!errors.zoneInfo?.landSurface}
              {...register('zoneInfo.landSurface')}
            />
          </Col>
        </Grid>
      </Paper>

      <Text fw={700} mt="xl">
        Produits utilisés
      </Text>

      <Paper p="xl" mt="xs">
        <Text>Merci de renseigner le nombre de produits installés sur votre chantier</Text>

        <Table striped withBorder mt="xl">
          <thead>
            <tr>
              <th>Produit</th>
              <th>Quantité</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((product, index) => (
              <tr key={index}>
                <td>{product.label}</td>
                <td>
                  <TextInput
                    type="number"
                    id={`products[${index}].qte`}
                    placeholder="Quantité"
                    error={!!errors.products?.[index]?.qte}
                    {...register(`products.${index}.qte`)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>

      <div
        style={{
          paddingTop: '10px',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
        }}
      >
        <Button leftIcon={<IconDeviceFloppy />} loading={loading} onClick={onSubmit} ml="md">
          Enregistrer
        </Button>

        <Button color="gray" leftIcon={<IconArrowLeft />} component={Link} to="/">
          Annuler
        </Button>
      </div>
    </form>
  );
};

export default CaseForm;
