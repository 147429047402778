import React, { FC } from 'react';
import * as CaseService from './service';
import Page, { PageProps } from '@layout/page/Page';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { parseQueriesFormUrl } from '@shared/utils/queries';
import { getPageQuery } from '@shared/modules/range/utils';
import { Case, casesFilterParser, CasesRangeFilter } from '@modules/cases/model';
import { useRange, VirtualizedList } from '@shared/modules/range';
import { identity } from 'fp-ts/function';
import { useProfile } from '@modules/auth/profile-loader';
import { Alert, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Filters } from '@shared/modules/filter/components';
import CasesFilter from '@modules/cases/components/list/CasesFilter';
import { IconPlus } from '@tabler/icons-react';
import CaseRow from '@modules/cases/components/row/CaseRow';

const loader = defineLoader({
  handler: ({ request }) => {
    const queries = parseQueriesFormUrl(request.url);

    return httpTaskToResponseTask(CaseService.getCasesRange(getPageQuery(queries), casesFilterParser(queries)));
  },
});

const CasesListPage: FC = () => {
  const { range, handleFilter, handleLoadPage } = useRange<typeof loader, Case, CasesRangeFilter>(identity);

  const pageProps: PageProps = {
    title: 'Mes chantiers',
  };

  const profile = useProfile();

  const handleClean = () => {
    handleFilter({
      search: null,
      status: null,
    });
  };

  return (
    <Page {...pageProps}>
      {profile.mustDeclareStock ? (
        <Alert color="red" style={{ border: '1px solid red' }} mb="xl">
          Vous êtes invité à déclarer votre stock restant de produits Sentri Tech
          <Button component={Link} to="/stocks" color="red" ml={10}>
            Déclarer mon stock
          </Button>
        </Alert>
      ) : null}

      <Filters search filter={range.filter} onFilter={handleFilter} clean onClean={handleClean}>
        {props => <CasesFilter {...props} />}
      </Filters>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button component={Link} to="/cases/new" leftIcon={<IconPlus />} mt="md">
          Nouveau
        </Button>
      </div>

      <VirtualizedList range={range} loadPage={handleLoadPage}>
        {({ item, style, ref, index }) => <CaseRow item={item} index={index} forwardRef={ref} style={style} />}
      </VirtualizedList>
    </Page>
  );
};

const casesListRoute = defineRoute({
  component: CasesListPage,
  loader,
});

export default casesListRoute;
